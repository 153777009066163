import React, { useEffect, useRef, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { PageContainer } from '@components/PageContainer';
import { AccessibleNav } from '@components/NavigationBarComponents/AccessibleNav';
import { RegionSelectionDialog } from '@components/RegionSelection/RegionSelectionDialog';
import { PageBreadcrumb } from '@components/PageBreadcrumb';
import { Footer } from '@components/Footer';
import { ErrorPage } from '@components/ErrorPage';
import { useStaticValues } from '@stateManagement/StaticValuesContext';

export function PublicLayout(): JSX.Element {
    const { notFound } = useStaticValues();
    const isInitialLoad = useRef(true);
    // 'notFound' initial data can still be true on a valid route accessed with client side navigation
    // This ensures we no longer use the 'notFound' initial data value to determine when to show the not found page
    const [hasRouteChanged, setHasRouteChanged] = useState(false);
    const { pathname } = useLocation();

    useEffect(() => {
        if (isInitialLoad.current) {
            isInitialLoad.current = false;
        } else {
            setHasRouteChanged(true);
        }
    }, [pathname]);

    return (
        <>
            <AccessibleNav />
            <PageBreadcrumb />
            <PageContainer>{notFound && !hasRouteChanged ? <ErrorPage /> : <Outlet />}</PageContainer>
            <RegionSelectionDialog />
            <Footer />
        </>
    );
}
