import { atom } from 'jotai';
import { getLanguageById } from '@helpers/languages';
import { LanguageCode } from '@models/languageCode';

export const isMobileAtom = atom(false);

export const selectedLanguageCodeAtom = atom<LanguageCode>(LanguageCode.en);

export const selectedLanguageAtom = atom(
    (get) => get(selectedLanguageCodeAtom),
    (_get, set, languageId: number) => {
        const language = getLanguageById(languageId)?.code || LanguageCode.en;
        set(selectedLanguageCodeAtom, language);
    }
);

export const navHeightAtom = atom(101);

export const remSizeAtom = atom(10);

export const isRegionSelectionDialogOpenAtom = atom(false);