import React from 'react';
import { breakpoints, Container } from '@web-for-marketing/react-ui';

const classes = {
    container: (spacing?: string) => ({
        paddingTop: spacing || '8rem',
        paddingBottom: spacing || '8rem',
        [`@media (max-width: ${breakpoints.md})`]: {
            paddingTop: '4rem',
            paddingBottom: '4rem',
        },
    }),
};

interface SectionContainerProps extends React.ComponentProps<typeof Container> {
    spacing?: string;
}

export function SectionContainer({ children, spacing, ...otherProps }: SectionContainerProps): JSX.Element {
    return (
        <Container css={classes.container(spacing)} {...otherProps}>
            {children}
        </Container>
    );
}
